import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import styled from "styled-components"
import * as v from "../components/variables"

import Header from "../components/Header"
import NavList from "../components/NavList"
import NavListItem from "../components/NavList/item"

import LogoIcon from "./images/logo.svg"

const Container = styled.div`
  ${v.grid}
`

const Logo = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  ${v.grid};

  img {
    grid-column: main;
    margin-top: ${v.baseline(2)};
    height: ${v.baseline(5)};
  }

  &:after {
    content: "";
    display: block;
    grid-column: full;
  }
`

const IndexPage = ({ data }) => {
  const home = data.home.nodes[0]
  const therapies = home.therapies.value

  console.log("Therapies", therapies)
  // console.log("Home", home)

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <Header
          title={home.title.value}
          subtitle={home.subtitle.value}
          image={home.header.value.url}
          color
        >
          <Logo>
            <img src={LogoIcon}></img>
          </Logo>
        </Header>
        <NavList title="Choose a therapy:">
          {therapies.map(therapy => (
            <NavListItem
              key={therapy.name.slug}
              link={therapy.name.slug}
              label={therapy.name.value}
            ></NavListItem>
          ))}
        </NavList>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    home: allCockpitHome {
      nodes {
        header {
          value {
            url
          }
        }
        title {
          value
        }
        subtitle {
          value
        }
        therapies {
          value {
            name {
              slug
              value
            }
          }
        }
      }
    }

    therapies: allCockpitTherapy {
      edges {
        node {
          name {
            value
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
